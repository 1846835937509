import classes from "./Title.module.css";

const Title = ({ children, styles }) => {
  return (
    <h1 className={classes.Title} style={styles}>
      {children}
    </h1>
  );
};

export default Title;

import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../types/snackbarTypes";

const initialState = {
  snackbarState: false,
  snackbarMessage: "",
  snackbarSeverity: "success",
};

export default function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbarState: true,
        snackbarMessage: action.snackbarMessage,
        snackbarSeverity: action.snackbarSeverity,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarState: false,
      };
    default:
      return { ...state };
  }
}

import { axiosConfig } from "../../axios/axios-api";
import { SnackbarMessages, SeverityTypes } from "../../constants";
import { LocalStorageService } from "../../services/LocalStorageService";
import { updateAccessToken } from "../actions/authActions";
import { openSnackbar } from "../actions/snackbarActions";
import {
  getUserFailed,
  getUserSuccess
} from "../actions/userActions";
import { signOut } from "./authEffects";

export function getPlayer() {
  return async (dispatch) => {
    try {
      const { data } = await axiosConfig.get(`user/getPlayerInfo`);
      dispatch(getUserSuccess(data));
    } catch (error) {
      dispatch(getUserFailed(error));
      dispatch(signOut());
      dispatch(
        openSnackbar(SnackbarMessages.AUTHORIZATION_TIMED_OUT, SeverityTypes.WARNING)
      );
    }
  };
}

export function updateCurrency(params, callback) {
  return async (dispatch) => {
    try {
      const { data: { accessToken } } = await axiosConfig.put(`user/updateCurrency`, params);
      dispatch(updateAccessToken(accessToken));
      LocalStorageService.setToken(accessToken);
      await dispatch(getPlayer());
      if (callback) callback()
    } catch (error) {
      if (callback) callback()
      dispatch(
        openSnackbar(SnackbarMessages.SOMETHING_WENT_WRONG, SeverityTypes.WARNING)
      );
    }
  };
}
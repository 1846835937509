import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Description from "../../components/Description/Description";
import SnackbarWrapper from "../../components/Snackbar/SnackbarWrapper";
import Title from "../../components/Title/Title";
import { history } from "../../history/history";
import classes from "./Home.module.css";

const Home = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <React.Fragment>
      <div className={classes.Home}>
        <Title>
          Welcome to
          <br></br>
          <span>ProFair Client Mock Platform</span>
        </Title>

        <Description>
          Here is an example of Client - ProFair Games API integration <br></br>
        </Description>
        {user && (
          <Button
            className={classes.GameButton}
            style={{ backgroundColor: "#2F1160", fontWeight: "bold" }}
            variant="contained"
            onClick={() => history.navigate("/games")}
          >
            Games
          </Button>
        )}
      </div>
      <SnackbarWrapper />
    </React.Fragment>
  );
};

export default Home;

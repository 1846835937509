import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import classes from "./Currency.module.css";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../store/actions/snackbarActions";
import { updateCurrency } from "../../store/effects/userEffects";
import { SnackbarMessages, SeverityTypes } from "../../constants";

const Currency = () => {
    const { currencies, error } = useSelector((state) => state.platform);
    const { user } = useSelector((state) => state.user);
    const [currency, setCurrency] = useState(user.currency);
    const [loading, setLoading] = useState(false);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const { userName } = user;

    const goToMainPage = () => {
        navigateTo("/");
    }

    const handleChange = (value) => setCurrency(value);

    const handleSubmit = () => {
        if (currency === user.currency) {
            goToMainPage();
            return;
        }
        setLoading(true);
        dispatch(updateCurrency({ currency, userName }, goToMainPage));
    };

    useEffect(() => {
        if (error) {
            dispatch(
                openSnackbar(SnackbarMessages.SOMETHING_WENT_WRONG, SeverityTypes.WARNING)
            );
            goToMainPage();
        }
    }, [error]);

    const renderCurrenciesList = (currencies) => {
        return currencies.map((currency, id) => <MenuItem key={id} value={currency}>{currency}</MenuItem>);
    }

    const currenciesList = currencies ? renderCurrenciesList(currencies) : null;

    return (
        <div className={classes.Currency}>
            <h1 className={classes.CurrencyTitle}>Сhange Сurrency</h1>

            <div className={classes.DropList}>
                <FormControl fullWidth>
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                        labelId="currency-label"
                        value={currency}
                        label="Currency"
                        onChange={({ target: { value } }) => handleChange(value)}
                    >
                        {currenciesList}
                    </Select>
                </FormControl>
            </div>

            <Button style={{ backgroundColor: loading ? "#2F1160" : "#C44FC8", width: "100%" }}
                variant="contained"
                onClick={handleSubmit}
            >{loading ? "Loading" : "Change"}
            </Button>
        </div >
    );
};

export default Currency;

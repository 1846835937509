import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../store/effects/authEffects";
import settings from "../../images/settings.png";
import User from "../User/User";
import classes from "./Header.module.css";

const Header = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const checkIfLogged = () => {
    if (user) {
      return (
        <div className={classes.UserActionsWrapper}>
          <User user={user} />
          <div className={classes.UserActionsButtons}>
            <Button
              style={{ backgroundColor: "#2F1160", width: 50 }}
              variant="contained"
              onClick={() => navigateTo("/currency")}
            >
              <img className={classes.SettingsImage} src={settings} alt="settings" />
            </Button>
            <Button
              style={{ backgroundColor: "#2F1160" }}
              variant="contained"
              onClick={() => dispatch(signOut())}
            >
              Sign Out
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.HeaderButtonsDiv}>
        <Button
          style={{ backgroundColor: "#2F1160" }}
          variant="contained"
          onClick={() => navigateTo("/auth/login")}
        >
          Log in
        </Button>
        <Button
          style={{ backgroundColor: "#2F1160" }}
          variant="contained"
          onClick={() => navigateTo("/auth/register")}
        >
          Sign up
        </Button>
      </div>
    );
  };

  return (
    <header className={classes.Header}>
      <div className={classes.HeaderContent}>
        {checkIfLogged()}
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "../containers/Auth/Auth";
import Home from "../containers/Home/Home";
import Games from "../containers/Games/Games";
import Currency from "../containers/Currency/Currency";
import ProtectedRoutes from "../containers/ProtectedRoutes/ProtectedRoutes";

const MainPagesRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/auth/*" element={<Auth />} />
      <Route
        path="/games"
        element={
          <ProtectedRoutes>
            <Games />
          </ProtectedRoutes>
        }
      />
      <Route path="/currency" element={
        <ProtectedRoutes>
          <Currency />
        </ProtectedRoutes>
      } />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default MainPagesRoutes;

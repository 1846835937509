import React from "react";
import { LocalStorageService } from "../../services/LocalStorageService";
import classes from "./Games.module.css";

const Games = () => {
  const token = LocalStorageService.getToken();

  return (
    <div className={classes.Games}>
      <iframe
        className={classes.Frame}
        src={`${process.env.REACT_APP_LOBBY_URL}?authToken=${token}`}
        title="lobby"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default Games;

import {
    GET_SUPPORTED_CURRENCIES_FAILED,
    GET_SUPPORTED_CURRENCIES_SUCCESS
} from "../types/platformTypes";

const initialState = {
    currencies: null,
    error: null
};

export default function platformReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SUPPORTED_CURRENCIES_SUCCESS:
            return { ...state, currencies: action.currencies };
        case GET_SUPPORTED_CURRENCIES_FAILED:
            return { ...state, error: action.error };
        default:
            return { ...state };
    }
}
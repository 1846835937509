import { GET_SUPPORTED_CURRENCIES_SUCCESS, GET_SUPPORTED_CURRENCIES_FAILED } from "../types/platformTypes";

export function getSupportedCurrenciesSuccess(currencies) {
    return ({
        type: GET_SUPPORTED_CURRENCIES_SUCCESS,
        currencies
    })
}

export function getSupportedCurrenciesSuccessFailed(error) {
    return ({
        type: GET_SUPPORTED_CURRENCIES_FAILED,
        error
    })
} 
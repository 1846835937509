import { axiosConfig } from "../../axios/axios-api";
import { SeverityTypes, SnackbarMessages } from "../../constants";
import { history } from "../../history/history";
import { LocalStorageService } from "../../services/LocalStorageService";
import {
  loginFailed,
  loginSuccess,
  registerFailed,
  registerSuccess,
  signOutSuccess,
} from "../actions/authActions";
import { openSnackbar } from "../actions/snackbarActions";
import { removeUser } from "../actions/userActions";
import { getPlayer } from "./userEffects";

export function login({ userName, password }) {
  return async (dispatch) => {
    try {
      const {
        data: { accessToken },
      } = await axiosConfig.post(`auth/login`, {
        userName,
        password,
      });
      dispatch(loginSuccess(accessToken));
      LocalStorageService.setToken(accessToken);
      dispatch(getPlayer());
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };
}

export function register({ userName, password, currency, balance }) {
  return async (dispatch) => {
    try {
      await axiosConfig.post(`auth/register`, {
        userName,
        password,
        currency,
        balance,
      });
      dispatch(registerSuccess());
      history.navigate("/auth/login");
      dispatch(
        openSnackbar(SnackbarMessages.LOG_IN_PERMITTED, SeverityTypes.SUCCESS)
      );
    } catch (error) {
      const { message } = error.response.data;
      dispatch(registerFailed(message));
      dispatch(openSnackbar(message, SeverityTypes.ERROR));
    }
  };
}

export function signOut() {
  return (dispatch) => {
    LocalStorageService.removeToken();
    dispatch(signOutSuccess());
    dispatch(removeUser());
  };
}

import React from "react";
import { useDispatch } from "react-redux";
import AuthForm from "../../containers/AuthForm/AuthForm";
import { register } from "../../store/effects/authEffects";
import SnackbarWrapper from "../Snackbar/SnackbarWrapper";

const Register = () => {
  const dispatch = useDispatch();

  const registerUser = (data) => {
    dispatch(register(data));
  };

  return (
    <React.Fragment>
      <AuthForm type="register" onRegister={registerUser}></AuthForm>
      <SnackbarWrapper />
    </React.Fragment>
  );
};

export default Register;

export class PrepareRegisterDataUtil {
  static get({ userName, password, currency, balance }) {
    const preparedData = {
      userName,
      password,
      currency,
      balance: Number(balance),
    };

    return preparedData;
  }
}

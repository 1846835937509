import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  SIGN_OUT_SUCCESS,
  UPDATE_ACCESS_TOKEN
} from "../types/authTypes";

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  error: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, accessToken: action.accessToken };
    case LOGIN_FAILED:
      return { ...state, error: action.error };
    case REGISTER_SUCCESS:
      return { ...state };
    case REGISTER_FAILED:
      return { ...state, error: action.error };
    case SIGN_OUT_SUCCESS:
      return { ...state, isLoggedIn: false, accessToken: null, error: null };
    case UPDATE_ACCESS_TOKEN:
      return { ...state, accessToken: action.accessToken };
    default:
      return { ...state };
  }
}

import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  SIGN_OUT_SUCCESS,
  UPDATE_ACCESS_TOKEN
} from "../types/authTypes";

export function loginSuccess(accessToken) {
  return {
    type: LOGIN_SUCCESS,
    accessToken,
  };
}

export function updateAccessToken(accessToken) {
  return {
    type: UPDATE_ACCESS_TOKEN,
    accessToken
  }
}

export function loginFailed(error) {
  return {
    type: LOGIN_FAILED,
    error,
  };
}

export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}

export function registerFailed(error) {
  return {
    type: REGISTER_FAILED,
    error,
  };
}

export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import platformReducer from "./platformReducer";
import snackbarReducer from "./snackbarReducer";
import userReducer from "./userReducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  platform: platformReducer
});

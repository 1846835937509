import React from "react";
import { AUTH_FORM_STYLES } from "../../constants/styles";
import classes from "./AuthForm.module.css";
import { LoginForm } from "./LoginForm/LoginForm";
import { RegisterFrom } from "./RegisterForm/RegisterForm";
import { PrepareRegisterDataUtil } from "../../utils/PrepareRegisterDataUtil";

const AuthForm = ({ type, onLogin, onRegister }) => {
  const styles = AUTH_FORM_STYLES;

  const renderNeededForm = () => {
    switch (type) {
      case "login":
        return <LoginForm styles={styles} onSubmit={handleLogin} />;
      case "register":
        return <RegisterFrom styles={styles} onSubmit={handleRegister} />;
    }
  };

  const handleLogin = (data, resetForm) => {
    onLogin(data);
    resetForm();
  };

  const handleRegister = (data, resetForm) => {
    const preparedRegisterData = PrepareRegisterDataUtil.get(data);
    resetForm();
    onRegister(preparedRegisterData);
  };

  return (
    <React.Fragment>
      <div className={classes.FormWrapper}>{renderNeededForm()}</div>
    </React.Fragment>
  );
};

export default AuthForm;

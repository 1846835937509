import axios from "axios";
import { LocalStorageService } from "../services/LocalStorageService";

export const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosConfig.interceptors.request.use(
  (config) => {
    const accessToken = LocalStorageService.getToken();
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

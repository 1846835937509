import {
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  REMOVE_USER
} from "../types/userTypes";

const initialState = {
  user: null,
  error: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return { ...state, user: action.user };
    case GET_USER_FAILED:
      return { ...state, error: action.error };
    case REMOVE_USER:
      return { ...state, user: null };
    default:
      return { ...state };
  }
}

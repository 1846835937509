import React, { useEffect } from "react";
import AuthRoutes from "../../routes/AuthRoutes";
import classes from "./Auth.module.css";
import { useSelector } from "react-redux";
import { history } from "../../history/history";

const Auth = () => {
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      history.navigate("/");
    }
  }, [user]);

  return (
    <div className={classes.Auth}>
      <AuthRoutes />
    </div>
  );
};

export default Auth;

import React from "react";
import Header from "../../components/Header/Header";
import classes from "./Layout.module.css";

const Layout = (props) => {
  const state = {
    isLogged: false,
  };

  return (
    <React.Fragment>
      <div className={classes.Layout}>
        <Header className={classes.Header} isLogged={state.isLogged} />

        <main className={classes.Main}>{props.children}</main>
      </div>
    </React.Fragment>
  );
};

export default Layout;

export class LocalStorageService {
  static setToken(authToken) {
    localStorage.setItem("token", authToken);
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static removeToken() {
    localStorage.removeItem("token");
  }
}

export const AUTH_FORM_STYLES = {
  title: {
    fontSize: 42,
  },
  form: {
    width: "100%",
  },
  formGroup: {
    width: "100%",
    gap: 20,
  },
  buttonWrapper: {
    paddingTop: "1rem",
  },
  button: {
    width: "100%",
  },
};

import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../store/actions/snackbarActions";

const SnackbarWrapper = () => {
  const dispatch = useDispatch();
  const { snackbarState, snackbarMessage, snackbarSeverity } = useSelector(
    (state) => state.snackbar
  );

  const closeHandler = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={snackbarState}
      autoHideDuration={6000}
      onClose={() => closeHandler()}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarWrapper;

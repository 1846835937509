import {
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  REMOVE_USER
} from "../types/userTypes";

export function getUserSuccess(user) {
  return {
    type: GET_USER_SUCCESS,
    user,
  };
}

export function getUserFailed(error) {
  return {
    type: GET_USER_FAILED,
    error,
  };
}

export function removeUser() {
  return {
    type: REMOVE_USER,
  };
}

import { axiosConfig } from "../../axios/axios-api";
import {
    getSupportedCurrenciesSuccess,
    getSupportedCurrenciesSuccessFailed
} from "../actions/platformActions";

export function getSupportedCurrencies() {
    return async (dispatch) => {
        try {
            const { data } = await axiosConfig.get(`platform/supportedCurrencies`);
            dispatch(getSupportedCurrenciesSuccess(data));
        } catch (error) {
            dispatch(getSupportedCurrenciesSuccessFailed(error));
        }
    }
};
import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../types/snackbarTypes";

export function openSnackbar(message, severity) {
  return {
    type: OPEN_SNACKBAR,
    snackbarMessage: message,
    snackbarSeverity: severity,
  };
}

export function closeSnackbar() {
  return {
    type: CLOSE_SNACKBAR,
  };
}

import { socket } from "./socket";

export class SocketModel {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  subscribe(newUser, callback) {
    socket.on(this.getUserBalanceEventName(newUser.id), () => callback());
  }

  connect(newUser, callback) {
    if (!this.user) {
      this.setUser(newUser);
      this.subscribe(newUser, callback);
      return;
    }

    if (this.user.id != newUser.id) {
      this.unsubscribe();
      this.subscribe(newUser, callback);
      this.setUser(newUser);
    }
  }

  setUser(newUser) {
    this.user = newUser;
  }

  unsubscribe() {
    socket.off(this.getUserBalanceEventName(this.user.id));
  }

  getUserBalanceEventName(userId) {
    return `USER_BALANCE_CHANGED#${userId}`;
  }
}

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Title from "../../../components/Title/Title";

const useStyles = makeStyles({
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
});

export const RegisterFrom = ({ styles, onSubmit }) => {
  const classes = useStyles();
  const { currencies } = useSelector((state) => state.platform);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userName: "",
      password: "",
      currency: "USD",
      balance: 1000,
    },
    mode: "onBlur",
  });

  const handleKeyDown = (event) => {
    const disallowedKeys = ["+", "-", "e", "E", "."];
    if (disallowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <Title styles={styles.title}>Register form</Title>
      <form
        style={styles.form}
        onSubmit={handleSubmit((registerData) => onSubmit(registerData, reset))}
      >
        <FormGroup style={styles.formGroup}>
          <FormControl>
            <TextField
              {...register("userName", {
                required: true,
                minLength: {
                  value: 4,
                  message: "Username must be longer than 4 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Username must be less than 20 characters",
                },
                pattern: {
                  value: /^[a-zA-Z0-9][a-zA-Z0-9_\-]*$/,
                  message:
                    "UserName must start with an alphanumeric character and can only contain alphanumeric characters, underscores, and hyphens.",
                },
              })}
              label="User name"
              variant="outlined"
            />
            <FormHelperText style={{ color: "red" }}>
              {errors.userName?.message}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <TextField
              {...register("password", {
                required: true,
                minLength: {
                  value: 4,
                  message: "Password must be longer than 4 characters",
                },
              })}
              label="Password"
              variant="outlined"
              type="password"
            />
            <FormHelperText style={{ color: "red" }}>
              {errors.password?.message}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel id="currency-label">Currency</InputLabel>
            <Controller
              name="currency"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  labelId="currency-label"
                  label="Сurrency"
                  placeholder="Currency"
                  {...field}
                >
                  {currencies &&
                    currencies.map((currency) => (
                      <MenuItem value={currency} key={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            <FormHelperText style={{ color: "red" }}>
              {errors.currency?.message}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <TextField
              {...register("balance", {
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: "Please enter a valid balance",
                },
                min: {
                  value: 1000,
                  message: "Your balance must be more than 1000",
                },
                max: {
                  value: 999999,
                  message: "Your balance must be less than 999999",
                },
                required: {
                  value: true,
                  message: "The balance amount cannot be empty",
                },
              })}
              type="number"
              inputProps={{
                inputMode: "numeric",
                onKeyDown: handleKeyDown,
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              error={Boolean(errors.balance)}
              helperText={errors.balance?.message}
              label="Balance"
              variant="outlined"
            />
          </FormControl>
          <div style={styles.buttonWrapper}>
            <Button
              style={styles.button}
              color="secondary"
              variant="contained"
              type="submit"
            >
              Register
            </Button>
          </div>
        </FormGroup>
      </form>
    </React.Fragment>
  );
};

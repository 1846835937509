import classes from "./Description.module.css";

const Description = props => {
    const cls = [
        classes.Description, 
        classes[props.type] || ''
    ];

    return (
        <p className = { cls.join(' ') }>
            { props.children }
        </p>
    )
}


export default Description;
import React from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  TextField,
} from "@mui/material";
import Title from "../../../components/Title/Title";
import { useForm } from "react-hook-form";

export const LoginForm = ({ styles, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    reset,
  } = useForm({
    defaultValues: {
      userName: "",
      password: "",
    },
    mode: "onBlur",
  });

  return (
    <React.Fragment>
      <Title styles={styles.title}>Login form</Title>
      <form
        style={styles.form}
        onSubmit={handleSubmit((loginData) => onSubmit(loginData, reset))}
      >
        <FormGroup style={styles.formGroup}>
          <FormControl>
            <TextField
              {...register("userName", {
                required: {
                  value: true,
                  message: "User name is required",
                },
              })}
              id="outlined-basic"
              label="User name"
              variant="outlined"
            />
            {touchedFields["userName"] && (
              <FormHelperText style={{ color: "red" }}>
                {errors.userName?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <TextField
              {...register("password", {
                required: { value: true, message: "Password is required" },
              })}
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
            />
            <FormHelperText style={{ color: "red" }}>
              {errors.password?.message}
            </FormHelperText>
          </FormControl>
          <div style={styles.buttonWrapper}>
            <Button
              style={styles.button}
              color="secondary"
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </div>
        </FormGroup>
      </form>
    </React.Fragment>
  );
};

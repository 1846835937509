import React from "react";
import classes from "./User.module.css";
import { useMediaQuery } from "@mui/material";

const User = ({ user }) => {
  const { userName, balance, currency } = user;
  const matches = useMediaQuery("(max-width:450px)");

  return (
    <div className={classes.UserInfo}>
      <span className={classes.UserName}>
        {matches ? userName : "Hello, " + userName}
      </span>
      <span className={classes.UserBalance}>
        {matches
          ? balance + " " + currency
          : "Balance: " + balance + " " + currency}
      </span>
    </div>
  );
};

export default User;
